<head>
  <link rel="stylesheet" href="https://use.typekit.net/oov2wcw.css">
  <script type="text/javascript" src="https://www.gstatic.com/cv/js/sender/v1/cast_sender.js"></script>
</head>

<body style="background-color:transparent">

<!--button class="button" onclick="btnAction()">Cast</button-->
<script src="//www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1"></script>


<button is="google-cast-button" id="cast-button" style="--connected-color:purple; --disconnected-color:red" (click)="buttonAction()">
  <img src="../../../assets/cast_logo.png" width="25" height="20" />
</button>

<!--table [style.backgroundColor]="backgroundcolor" [style.td.color]="textcolor" [style.width]="screenwidth"-->
<table class="tk-century-gothic" #screen [style.backgroundColor]="backgroundcolor" [style.color]="textcolor" [style.width]="screenwidth" [style.height]="screenheight">

  <div *ngIf="screentype=='Menu'; else elseBlock">
  <ng-container *ngFor="let dta of itemsforthisscreen" >
    {{setCatg(dta.category)}}
    <tr  *ngIf="ifcatgchanged">
      <th class="greendot"></th>
      <th class="tk-century-gothic" style="width: 200px; font-size:40.83px;font-weight:400px">{{getFromCharCode(dta.category)}}</th>
      <ng-container *ngFor="let gram of screengrams" >
        <th style="width: 50px;">{{gram}}g</th>  
      </ng-container>
    </tr>

  <tr>
    <td class="greendot">🟢</td>
    <td class="tk-century-gothic firstrow" style="width: 200px;">{{dta.productName}}</td>
    <ng-container *ngFor="let gram of screengrams" >
      <td class="tk-century-gothic firstrow" style="width: 50px;">{{getGramPrice(dta.productName,gram)}}</td>
    </ng-container>
    <!--td>{{tablestyle.bgcolor}}</td-->
  </tr>
  <table>
  <tr>
    <td class="tk-century-gothic  secondrow" style="padding-left:32px;">{{dta.vendorName}} |</td>
    <td class="tk-century-gothic secondrow"> {{dta.thcContent}}%thc |</td>
    <td class="tk-century-gothic secondrow">{{dta.flavor}} |</td>
    <td class="tk-century-gothic secondrow">{{getTag(dta.productName)}}</td>
  </tr>
</table>
  </ng-container>
  </div>
  <ng-template #elseBlock>
    <div style="text-align: center;">
     <img class="bg" [src]="displayimage" [style.width]="imagewidth" >
    </div>
  </ng-template>

</table>

</body>

